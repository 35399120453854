.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
    padding-top: 20px;
    justify-content: center;
  }
  .my-masonry-grid_column {
    padding-left: 10px; /* gutter size */
    background-clip: padding-box;
  }
  
  /* Style your items */
  .my-masonry-grid_column > div { 
    max-width: 300px;
    margin-top: 10px;
    
  }
  /* Optional, different gutter size on mobile */
@media (max-width: 800px) {
  .my-masonry-grid {
    margin-left: -15px; /* gutter size offset */
  }
  .my-masonry-grid_column {
    padding-left: 15px; /* gutter size offset */
  }
  .my-masonry-grid_column > div {
    margin-bottom: 15px; /* space between items */
  }
}